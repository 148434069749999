.pac-container {
  background-color: white;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  z-index: 1301 !important;
  width: 100%;
}
.pac-item,
.pac-item-query {
  color: #add450;
}
