/* Media query for mobile viewport */
@media screen and (max-width: 400px) {
  .paypal-button-container {
    width: 100%;
  }
}

/* Media query for desktop viewport */
@media screen and (min-width: 400px) {
  .paypal-button-container {
    width: 250px;
    display: inline-block;
  }
}
