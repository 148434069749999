@font-face {
  font-family: "orda";
  src: url("../assets/fonts/icomoon.eot?1bic16");
  src: url("../assets/fonts/icomoon.eot?1bic16#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/icomoon.ttf?1bic16") format("truetype"),
    url("../assets/fonts/icomoon.woff?1bic16") format("woff"),
    url("../assets/fonts/icomoon.svg?1bic16#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="orda-icon-"],
[class*=" orda-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "orda" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 0.8em;
}

.orda-icon-ORDA:before {
  content: "\e900";
}
